import React, { Component } from "react";

class Maincomponent extends Component {
  constructor(props) {
    super(props);
    this.componentClasses = {
      container: `container`,
      row: `row my-4`,
      column: `col-10 mx-auto shadow-sm p-3 mb-5 bg-dark rounded`,
      table: {
        firstHead: `Paslaugos teikėjas`,
        secondHead: `Aprašymas`,
        thirdHead: `Nuoroda`,
      },
    };
    this.styles = {
      headingFontStyle: {
        color: `white`,
      },
      grayStyle: {
        backgroundColor: `#333`,
      },
    };
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    async function fetchData() {
      const req = await fetch("https://referals.artefaktas.workers.dev/api");
      const jsonData = await req.json();
      return jsonData;
    }
    fetchData().then((res) => {
      this.setState({ data: res });
    });
  }

  render() {
    return (
      <div className={this.componentClasses.container}>
        <div
          className={this.componentClasses.row}
          style={this.styles.grayStyle}
        >
          <div className={this.componentClasses.column}>
            <h2 style={this.styles.headingFontStyle}>Artefaktas referals</h2>
          </div>
        </div>
        <div
          className={this.componentClasses.row}
          style={this.styles.grayStyle}
        >
          <div className={this.componentClasses.column}>
            <div id="google_translate_element"></div>
          </div>
        </div>
        {this.state.data !== null ? (
          <div className={this.componentClasses.row}>
            <div className={this.componentClasses.column}>
              <div className="table-responsive">
                <table className="table table-striped table-dark">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">
                        {this.componentClasses.table.firstHead}
                      </th>
                      <th scope="col">
                        {this.componentClasses.table.secondHead}
                      </th>
                      <th scope="col">
                        {this.componentClasses.table.thirdHead}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((link, ind) => {
                      return (
                        <tr key={ind}>
                          <th scope="row">{ind + 1}</th>
                          <td>{link.provider}</td>
                          <td>{link.description}</td>
                          <td>
                            <a
                              href={link.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.link}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}

export default Maincomponent;
